import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { Link } from 'react-router-dom';
import { getLanguageCodeFromSession } from "../../../helpers/CommonHelper";
import Config from "../../../helpers/Config";
import { MakeApiCallAsync } from "../../../helpers/ApiHelpers";
import "./BennerSlider1.css";

const BannerSlider1 = () => {
  const [bannerList, setBannerList] = useState([]);
  const [adminPanelBaseURL, setBaseUrl] = useState(Config['ADMIN_BASE_URL']);

  useEffect(() => {
    const dataOperationFunc = async () => {
      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      };

      const param = {
        requestParameters: {
          recordValueJson: "[]",
        },
      };

      const response = await MakeApiCallAsync(Config.END_POINT_NAMES['GET_HOME_SCREEN_BANNER'], null, param, headers, "POST", true);
      if (response && response.data) {
        setBannerList(JSON.parse(response.data.data));
      }
    };

    dataOperationFunc().catch(console.error);
  }, []);

  return (
    <section className="theme-slider b-g-white" id="theme-slider">
      <div className="custom-container">
        <Row>
          <Col>
            <div id="carouselExampleDark" className="carousel carousel-dark slide" data-bs-ride="carousel">
              <div className="carousel-indicators">
                {bannerList.filter(x => x.ThemeTypeID === 2).map((banner, index) => (
                  <button
                    key={index}
                    type="button"
                    data-bs-target="#carouselExampleDark"
                    data-bs-slide-to={index}
                    className={index === 0 ? "active" : ""}
                    aria-current={index === 0 ? "true" : "false"}
                    aria-label={`Slide ${index + 1}`}
                  ></button>
                ))}
              </div>
              <div className="carousel-inner">
                {bannerList.filter(x => x.ThemeTypeID === 2).map((banner, index) => (
                  <div
                    key={index}
                    className={`carousel-item ${index === 0 ? "active" : ""}`}
                    data-bs-interval="2500"
                  >
                    <img
                      src={adminPanelBaseURL + banner.BannerImgUrl}
                      className="d-block w-100"
                      alt="slider"
                    />
                    <div className="carousel-caption  d-md-block">
                      <h3 className="home-top-title">{banner.TopTitle}</h3>
                      <h1>{banner.MainTitle}</h1>
                      <h2>{banner.BottomTitle}</h2>
                      <Link
                        className="btn home-btn-banner-left"
                        to={`/${getLanguageCodeFromSession()}/all-products/0/all-categories`}
                      >
                        {banner.LeftButtonText}
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleDark"
                data-bs-slide="prev"
              >
                {/* <span className="carousel-control-prev-icon" aria-hidden="true"></span> */}
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleDark"
                data-bs-slide="next"
              >
                {/* <span className="carousel-control-next-icon" aria-hidden="true"></span> */}
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default BannerSlider1;
