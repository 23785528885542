import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Config from '../../../helpers/Config';
import SiteBreadcrumb from '../../components/layout/SiteBreadcrumb';
import { Media, Row, Col, Container } from "reactstrap";
import aboutImg from '../../../resources/custom/images/about-us.jpg';
import CustomerTestimonial from '../../components/shared/CustomerTestimonial';

const PrivacyPolicy = () => {
  const [siteTitle, setSiteTitle] = useState(Config['SITE_TTILE']);

  return (
    <>


      <Helmet>
        <title>{siteTitle} - Privacy Policy</title>
        <meta name="description" content={siteTitle + " - Privacy Policy page"}  />
        <meta name="keywords" content="Privacy Policy"></meta>
      </Helmet>

      <SiteBreadcrumb title="Privacy Policy" parent="Home"/>

      <section className="about-page section-big-py-space">
       <div className="container">
        <div className="row">
            <div className="col-12 " style={{marginTop:"-80px"}}>
                <h4>Privacy Policy</h4>
                <p>If you share your personal information with us or our other entities, we are entitled to disclose personal information to third parties. Parijat Handicraft may disclose your personal information if required to do so by law or in the good faith belief that such disclosure is reasonably necessary to respond to subpoenas, court orders, or other legal process. We are entitled to share some or all of your personal information with another business entity should we plan to merge with.</p>
            </div>
        </div>
       </div>
      </section>

      <CustomerTestimonial />

    </>
  );

}

export default PrivacyPolicy;
