import React from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Container } from "reactstrap";


const SiteBreadcrumb = ({title , parent}) => {
    const params=useParams()
    // console.log(params.category)   
    //  console.log("Kya mila hai",params.category)


    return (
        <>
          
            <div className="breadcrumb-main ">
                <Container>
                    <Row>
                        <Col>
                            <div className="breadcrumb-contain">
                                <div>
                                    <h2>{title}</h2>
                                    <ul>
                                        <li>
                                            <a href="#">{parent}</a>
                                        </li>
                                        <li>
                                            <i className="fa fa-angle-double-right"></i>
                                        </li>
                                        <li>
                                            <a href="#">{title}</a>
                                        </li>
                                        
                                        {params.category?
                                        <>
                                         <li>
                                            <i className="fa fa-angle-double-right"></i>
                                        </li>
                                        <li>
                                           <a href="#">{params.category}</a>

                                        </li>
                                        </>
                                        :""}
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );

}

export default SiteBreadcrumb;