
// import { replaceLoclizationLabel } from '../../../helpers/CommonHelper';
// import React, { useEffect, useState } from "react";
// import { TabContent, TabPane, Row, Col } from "reactstrap";
// import Slider from "react-slick";
// import { useDispatch } from 'react-redux';

// import { getLanguageCodeFromSession, GetLocalizationControlsJsonDataForScreen } from "../../../helpers/CommonHelper";
// import Config from "../../../helpers/Config";
// import { MakeApiCallAsync } from "../../../helpers/ApiHelpers";
// import GlobalEnums from "../../../helpers/GlobalEnums";
// import rootAction from "../../../stateManagment/actions/rootAction";
// import { LOADER_DURATION } from "../../../helpers/Constants";
// import ProductBoxHome from "../products/ProductBoxHome";
// import Skeleton from "../products/Skeleton/Skeleton";
// import ProductBoxPopulerCategory from '../products/ProductBoxPopulerCategory';

// var settings = {
//     arrows: true,
//     autoplay: true,
//     autoplaySpeed: 2500,
//     dots: false,
//     infinite: true,
//     speed: 300,
//     slidesToShow: 6,
//     slidesToScroll: 6,
//     responsive: [
//         {
//             breakpoint: 1700,
//             settings: {
//                 slidesToShow: 4,
//                 slidesToScroll: 5,
//                 infinite: true,
//             },
//         },
//         {
//             breakpoint: 1200,
//             settings: {
//                 slidesToShow: 4,
//                 slidesToScroll: 4,
//                 infinite: true,
//             },
//         },
//         {
//             breakpoint: 991,
//             settings: {
//                 slidesToShow: 3,
//                 slidesToScroll: 3,
//                 infinite: true,
//             },
//         },
//         {
//             breakpoint: 576,
//             settings: {
//                 slidesToShow: 2,
//                 slidesToScroll: 2,
//             },
//         },
//     ],
// };

// const NewPopulerCategory = ({ effect }) => {
//     const dispatch = useDispatch();
//     const [langCode, setLangCode] = useState('');
//     const [activeTab, setActiveTab] = useState("new products");
//     const [collection, setCollection] = useState([
//         { nameEn: "new products", nameAr: "منتجات جديدة" },
//         { nameEn: "on sale", nameAr: "للبيع" },
//         { nameEn: "hotdeal", nameAr: "صفقة حاسمة" },
//         { nameEn: "best sellers", nameAr: "أفضل البائعين" }
//     ]);

//     const [filteredProducts, setFilteredProducts] = useState([]);
//     const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
//     const [PaginationInfo, setPaginationInfo] = useState({
//         PageNo: 1,
//         PageSize: 20,
//         TotalRecords: 0
//     });

//     const getNewProductsList = async (TabName) => {
//         setActiveTab(TabName);

//         const headers = {
//             Accept: 'application/json',
//             'Content-Type': 'application/json',
//         }
//         const param = {
//             requestParameters: {
//                 PageNo: PaginationInfo.PageNo,
//                 PageSize: PaginationInfo.PageSize,
//                 TabName: TabName,
//                 recordValueJson: "[]",

//             },
//         };
//         const response = await MakeApiCallAsync(Config.END_POINT_NAMES['GET_RECENTS_PRODUCTS_LIST'], null, param, headers, "POST", true);
//         if (response != null && response.data != null) {
//             let ProductData = JSON.parse(response.data.data);
//             console.log("Received ProductData: ", ProductData); // Debugging
//             if (ProductData != undefined && ProductData.length > 0) {
//                 // Check if products have the category field and filter by "Sports & Outdoors"
//                 let filtered = ProductData.filter(product => {
//                     console.log("Product category: ", product.CategoryName); // Debugging
//                     return product.CategoryName === "Sports & Outdoors";
//                 });

//                 console.log("Filtered Products: ", filtered); // Debugging
//                 // Update state only with filtered products
//                 setFilteredProducts(filtered);
//             }
//         }
//     }
//     useEffect(() => {
//         const dataOperationInUseEffect = async () => {
//             let lnCode = getLanguageCodeFromSession();
//             setLangCode(lnCode);

//             await getNewProductsList("new products");
//             let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(GlobalEnums.Entities["NewProducts"], null);
//             if (arryRespLocalization != null && arryRespLocalization.length > 0) {
//                 setLocalizationLabelsArray(arryRespLocalization);
//             }
//         }

//         dispatch(rootAction.commonAction.setLoading(true));
//         dataOperationInUseEffect().catch(console.error);
//         setTimeout(() => {
//             dispatch(rootAction.commonAction.setLoading(false));
//         }, LOADER_DURATION);
//     }, []);
//     return (
//         <>
//             <div className="title6 ">
//                 <h4>
//                     {LocalizationLabelsArray.length > 0 ?
//                         replaceLoclizationLabel(LocalizationLabelsArray, "! Most Popular Varieties !", "lbl_popct_category")
//                         :
//                         "! Most Popular Varieties !"
//                     }
//                 </h4>
//             </div>
//             <section className="section-py-space ratio_asos product">
//                 <div className="custom-container">
//                     <div className="container">
//                         <div className="row">
//                             <h4>Sports & Outdoors</h4>
//                             <Col className="pe-0">
//                                 <div className="product product-slide-6 product-m no-arrow">
//                                     <div>
//                                         {filteredProducts.length === 0 ? (
//                                             <Skeleton />
//                                         ) : (
//                                             <Slider {...settings}>
//                                                 {filteredProducts.map((itm, i) => (
//                                                     <div key={i}>
//                                                         <ProductBoxPopulerCategory
//                                                             hoverEffect={effect}
//                                                             item={itm}
//                                                             layout=""
//                                                             ProductDetailPageForceUpload={false} />
//                                                     </div>
//                                                 ))}
//                                             </Slider>
//                                         )}
//                                     </div>
//                                 </div>

//                             </Col>

//                         </div>
//                         <div className="row">
//                             <h4>TOOLS & HOME IMPROVEMENT</h4>
//                             <Col className="pe-0">
//                                 <div className="product product-slide-6 product-m no-arrow">
//                                     <div>
//                                         {filteredProducts.length === 0 ? (
//                                             <Skeleton />
//                                         ) : (
//                                             <Slider {...settings}>
//                                                 {filteredProducts.map((itm, i) => (
//                                                     <div key={i}>
//                                                         <ProductBoxPopulerCategory
//                                                             hoverEffect={effect}
//                                                             item={itm}
//                                                             layout=""
//                                                             ProductDetailPageForceUpload={false} />
//                                                     </div>
//                                                 ))}
//                                             </Slider>
//                                         )}
//                                     </div>
//                                 </div>
//                             </Col>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//         </>
//     );
// };

// export default NewPopulerCategory;


import { replaceLoclizationLabel } from '../../../helpers/CommonHelper';
import React, { useEffect, useState } from "react";
import { Col } from "reactstrap";
import Slider from "react-slick";
import { useDispatch } from 'react-redux';

import { getLanguageCodeFromSession, GetLocalizationControlsJsonDataForScreen } from "../../../helpers/CommonHelper";
import Config from "../../../helpers/Config";
import { MakeApiCallAsync } from "../../../helpers/ApiHelpers";
import GlobalEnums from "../../../helpers/GlobalEnums";
import rootAction from "../../../stateManagment/actions/rootAction";
import { LOADER_DURATION } from "../../../helpers/Constants";
import ProductBoxPopulerCategory from '../products/ProductBoxPopulerCategory';
import Skeleton from "../products/Skeleton/Skeleton";
import "./NewPopulerCategory1.css"
var settings = {
    arrows: true,
    autoplay: true,
    autoplaySpeed: 2500,
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 6,
    slidesToScroll: 6,
    responsive: [
        {
            breakpoint: 1700,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 5,
                infinite: true,
            },
        },
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
                infinite: true,
            },
        },
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
            },
        },
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },
    ],
};

const NewPopulerCategory = ({ effect }) => {
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState("new products");
    const [ProductsList, setProductsList] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [filteredProducts1, setFilteredProducts1] = useState([]);
    const [filteredProducts2, setFilteredProducts2] = useState([]);
    const [filteredProducts3, setFilteredProducts3] = useState([]);
    const [filteredProducts4, setFilteredProducts4] = useState([]);
    const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
    
    const [PaginationInfo, setPaginationInfo] = useState({
        PageNo: 1,
        PageSize: 20,
        TotalRecords: 0
    });
    const [langCode, setLangCode] = useState('');

    const getNewProductsList = async (TabName) => {
        setActiveTab(TabName);

        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        };
        const param = {
            requestParameters: {
                PageNo: PaginationInfo.PageNo,
                PageSize: PaginationInfo.PageSize,
                TabName: TabName,
                recordValueJson: "[]",
            },
        };

        try {
            const response = await MakeApiCallAsync(Config.END_POINT_NAMES['GET_RECENTS_PRODUCTS_LIST'], null, param, headers, "POST", true);
            console.log("New Products Response: ", response); // Debugging
            if (response && response.data) {
                let ProductData = JSON.parse(response.data.data);
                console.log("Received ProductData: ", ProductData);
                if (Array.isArray(ProductData)) {
                    let filtered = ProductData.filter(product => product.CategoryName === "Sports & Outdoors");
                    console.log("Filtered Products: ", filtered);
                    setFilteredProducts(filtered);
                } else {
                    console.warn("ProductData is not an array.");
                }
            }
        } catch (error) {
            console.error("Error fetching new products:", error);
        }
    };


    useEffect(() => {
        const dataOperationInUseEffect = async () => {
            let lnCode = getLanguageCodeFromSession();
            setLangCode(lnCode);

            await getNewProductsList("new products");


            let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(GlobalEnums.Entities["NewProducts"], null);
            if (arryRespLocalization && Array.isArray(arryRespLocalization)) {
                setLocalizationLabelsArray(arryRespLocalization);
            } else {
                console.warn("Localization data is not an array or is null.");
            }
        };

        dispatch(rootAction.commonAction.setLoading(true));
        dataOperationInUseEffect().catch(console.error);
        setTimeout(() => {
            dispatch(rootAction.commonAction.setLoading(false));
        }, LOADER_DURATION);
    }, [dispatch]);



    //Fetch Data From All Products 

    useEffect(() => {
        const getAllProducts = async () => {
            const headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            };
    
            const param = {
                requestParameters: {
                    SearchTerm: null,
                    SizeID: null,
                    ColorID: null,
                    CategoryID: 0,
                    TagID:"",
                    ManufacturerID: "",
                    MinPrice: null,
                    MaxPrice: null,
                    Rating: null,
                    OrderByColumnName: "",
                    PageNo: 1,
                    PageSize: 2000,
                    recordValueJson: "[]",
                },
            };
    
            console.log("Request Parameters: ", param);
    
            try {
                const response = await MakeApiCallAsync(Config.END_POINT_NAMES['GET_All_PRODUCTS'], null, param, headers, "POST", true);
                console.log("API Response dsfgdgfd: ", response);
                if (response && response.data) {
                    console.log("Raw Response Data: ", response.data);
                    if (response.data.statusCode === 200) {
                        try {
                            let AllProducts = JSON.parse(response.data.data);
                            console.log("Parsed Products: ", AllProducts);
                            
                            // Filtered Product for Home & Kitchen Category
                            if (Array.isArray(AllProducts)) {
                                let filtered = AllProducts.filter(product => product.CategoryName === "Home & Kitchen");
                                console.log("Filtered Products Home & Kitchen: ", filtered);
                                setFilteredProducts1(filtered);
                            } else {
                                console.warn("ProductData is not an array.");
                            }

                            // Filtered Product for TOOLS & HOME IMPROVEMENT Category

                            if (Array.isArray(AllProducts)) {
                                let filtered = AllProducts.filter(product => product.CategoryName === "TOOLS & HOME IMPROVEMENT");
                                console.log("Filtered Products TOOLS & HOME IMPROVEMENT: ", filtered);
                                setFilteredProducts2(filtered);
                            } else {
                                console.warn("ProductData is not an array.");
                            }
                            // Filtered Product for Scarves & Wraps>>Wraps & Pashminas Category

                            if (Array.isArray(AllProducts)) {
                                let filtered = AllProducts.filter(product => product.CategoryName === "Scarves & Wraps>>Wraps & Pashminas");
                                console.log("Filtered Products Scarves & Wraps>>Wraps & Pashminas: ", filtered);
                                setFilteredProducts3(filtered);
                            } else {
                                console.warn("ProductData is not an array.");
                            }

                            // Filtered Product for Outdoor Indoor Decor

                            if (Array.isArray(AllProducts)) {
                                let filtered = AllProducts.filter(product => product.CategoryName === "Outdoor Indoor Decor");
                                console.log("Filtered Products Outdoor Indoor Decor : ", filtered);
                                setFilteredProducts4(filtered);
                            } else {
                                console.warn("ProductData is not an array.");
                            }

                        } catch (parseError) {
                            console.error("Parsing Error: ", parseError);
                            setProductsList(response.data.data); 
                        }
                    } else {
                        console.error(`API Error: ${response.data.statusMessage}`);
                    }
                } else {
                    console.warn("Response data is missing or malformed.");
                }
            } catch (error) {
                console.error("Error fetching all products:", error);
            } finally {
                // Stop loader
                dispatch(rootAction.commonAction.setLoading(false));
            }
        };
    
        // Call the function and handle any errors
        getAllProducts().catch(console.error);
    
        console.log("API Endpoint: ", Config.END_POINT_NAMES['GET_All_PRODUCTS']);
    
    }, [dispatch, PaginationInfo.PageNo, PaginationInfo.PageSize]);
    


    return (
        <>
            <div className="title6 ">
                <h4>
                    {LocalizationLabelsArray.length > 0 ?
                        replaceLoclizationLabel(LocalizationLabelsArray, "! Most Popular Varieties !", "lbl_popct_category")
                        :
                        "! Most Popular Varieties !"
                    }
                </h4>
            </div>
            <section className="section-py-space ratio_asos product">
                <div className="custom-container">
                    <div className="">

                        <div className="row">
                            <h4 className='headingOfCategory'>Sports & Outdoors</h4>
                            <Col className="pe-0">
                                <div className="product product-slide-6 product-m no-arrow">
                                    <div>
                                        {filteredProducts.length === 0 ? (
                                            <Skeleton />
                                        ) : (
                                            <Slider {...settings}>
                                                {filteredProducts.map((itm, i) => (
                                                    <div key={i}>
                                                        <ProductBoxPopulerCategory
                                                            hoverEffect={effect}
                                                            item={itm}
                                                            layout=""
                                                            ProductDetailPageForceUpload={false} />
                                                    </div>
                                                ))}
                                            </Slider>
                                        )}
                                    </div>
                                </div>
                            </Col>
                        </div>

                        <div className="row">
                            <h4 className='headingOfCategory'>Home & Kitchen</h4>
                            <Col className="pe-0">
                                <div className="product product-slide-6 product-m no-arrow">
                                    <div>
                                        {filteredProducts1.length === 0 ? (
                                            <Skeleton />
                                        ) : (
                                            <Slider {...settings}>
                                                {filteredProducts1.slice(0, 10).map((itm, i) => (
                                                    <div key={i}>
                                                        <ProductBoxPopulerCategory
                                                            hoverEffect={effect}
                                                            item={itm}
                                                            layout=""
                                                            ProductDetailPageForceUpload={false} />
                                                    </div>
                                                ))}
                                            </Slider>
                                        )}
                                    </div>
                                </div>
                            </Col>
                        </div>

                        <div className="row">
                            <h4 className='headingOfCategory'>TOOLS & HOME IMPROVEMENT</h4>
                            <Col className="pe-0">
                                <div className="product product-slide-6 product-m no-arrow">
                                    <div>
                                        {filteredProducts2.length === 0 ? (
                                            <Skeleton />
                                        ) : (
                                            <Slider {...settings}>
                                                {filteredProducts2.slice(0, 10).map((itm, i) => (
                                                    <div key={i}>
                                                        <ProductBoxPopulerCategory
                                                            hoverEffect={effect}
                                                            item={itm}
                                                            layout=""
                                                            ProductDetailPageForceUpload={false} />
                                                    </div>
                                                ))}
                                            </Slider>
                                        )}
                                    </div>
                                </div>
                            </Col>
                        </div>

                        <div className="row">
                            <h4 className='headingOfCategory'>Scarves & Wraps>>Wraps & Pashminas</h4>
                            <Col className="pe-0">
                                <div className="product product-slide-6 product-m no-arrow">
                                    <div>
                                        {filteredProducts3.length === 0 ? (
                                            <Skeleton />
                                        ) : (
                                            <Slider {...settings}>
                                                {filteredProducts3.slice(0, 10).map((itm, i) => (
                                                    <div key={i}>
                                                        <ProductBoxPopulerCategory
                                                            hoverEffect={effect}
                                                            item={itm}
                                                            layout=""
                                                            ProductDetailPageForceUpload={false} />
                                                    </div>
                                                ))}
                                            </Slider>
                                        )}
                                    </div>
                                </div>
                            </Col>
                        </div>

                        <div className="row">
                            <h4 className='headingOfCategory'>Outdoor Indoor Decor</h4>
                            <Col className="pe-0">
                                <div className="product product-slide-6 product-m no-arrow">
                                    <div>
                                        {filteredProducts4.length === 0 ? (
                                            <Skeleton />
                                        ) : (
                                            <Slider {...settings}>
                                                {filteredProducts4.slice(0, 10).map((itm, i) => (
                                                    <div key={i}>
                                                        <ProductBoxPopulerCategory
                                                            hoverEffect={effect}
                                                            item={itm}
                                                            layout=""
                                                            ProductDetailPageForceUpload={false} />
                                                    </div>
                                                ))}
                                            </Slider>
                                        )}
                                    </div>
                                </div>
                            </Col>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default NewPopulerCategory;



// import React, { useEffect, useState } from 'react';
// import { Row, Col, Media, Container } from "reactstrap";
// import { Link } from 'react-router-dom';
// import Config from '../../../helpers/Config';
// import { getLanguageCodeFromSession, GetLocalizationControlsJsonDataForScreen, replaceLoclizationLabel } from '../../../helpers/CommonHelper';
// import { MakeApiCallAsync } from '../../../helpers/ApiHelpers';
// import GlobalEnums from '../../../helpers/GlobalEnums';
// import { makeAnyStringLengthShort, replaceWhiteSpacesWithDashSymbolInUrl } from '../../../helpers/ConversionHelper';
// import "./NewPopulerCategory.css";

// const NewPopulerCategory = () => {
//   const [PopularCategoriesList, setPopularCategories] = useState([]);
//   const [adminPanelBaseURL, setBaseUrl] = useState(Config['ADMIN_BASE_URL']);
//   const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
//   const [langCode, setLangCode] = useState('');

//   useEffect(() => {
//     const getPopularCategories = async () => {
//       let lnCode = getLanguageCodeFromSession();
//       setLangCode(lnCode);

//       const headers = {
//         Accept: 'application/json',
//         'Content-Type': 'application/json',
//       };

//       const param = {
//         requestParameters: {
//           PageNo: 1,
//           PageSize: 20,
//           recordValueJson: "[]",
//         },
//       };

//       const response = await MakeApiCallAsync(Config.END_POINT_NAMES['GET_POPULAR_CATEGORIES'], null, param, headers, "POST", true);
//       if (response != null && response.data != null) {
//         setPopularCategories(JSON.parse(response.data.data));
//       }

//       let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(GlobalEnums.Entities["PopularCategories"], null);
//       if (arryRespLocalization != null && arryRespLocalization.length > 0) {
//         setLocalizationLabelsArray(arryRespLocalization);
//       }
//     };

//     getPopularCategories().catch(console.error);
//   }, []);

//   return (
//     <>
//       {PopularCategoriesList && PopularCategoriesList.length > 0 &&
//         <>
//           <div className="title6">
//             <h4>
//               {LocalizationLabelsArray.length > 0 ?
//                 replaceLoclizationLabel(LocalizationLabelsArray, "! Most Popular Varieties !", "lbl_popct_category")
//                 :
//                 "! Most Popular Varieties !"
//               }
//             </h4>
//           </div>

//           <section className="rounded-category-inverse">
//             <Container>
//               <Row>
//                 <Col>
//                   <div className="category-grid">
//                     {PopularCategoriesList.map((item, i) => (
//                       <div key={i} className='category-item'>
//                         <Link to={`/${getLanguageCodeFromSession()}/all-products/${item.CategoryID ?? 0}/${replaceWhiteSpacesWithDashSymbolInUrl(item.Name)}`}>
//                           <div className="img-container">
//                             <Media
//                               src={adminPanelBaseURL + item.AttachmentURL}
//                               alt="category"
//                               className="category-image"
//                               title={
//                                 langCode === Config.LANG_CODES_ENUM["Arabic"]
//                                   ? makeAnyStringLengthShort(item.LocalizationJsonData?.find(l => l.langId === Config.LANG_CODES_IDS_ENUM["Arabic"])?.text, 22)
//                                   : makeAnyStringLengthShort(item.Name, 22)
//                               }
//                             />
//                           </div>
//                           <div>
//                             <div className="btn-round">
//                               <Link to={`/${getLanguageCodeFromSession()}/all-products/${item.CategoryID ?? 0}/${replaceWhiteSpacesWithDashSymbolInUrl(item.Name)}`} style={{ color: 'inherit', textDecoration: 'none' }}>
//                                 {langCode === Config.LANG_CODES_ENUM["Arabic"]
//                                   ? makeAnyStringLengthShort(item.LocalizationJsonData?.find(l => l.langId === Config.LANG_CODES_IDS_ENUM["Arabic"])?.text, 22)
//                                   : makeAnyStringLengthShort(item.Name, 22)
//                                 }
//                               </Link>
//                             </div>
//                           </div>
//                         </Link>
//                       </div>
//                     ))}
//                   </div>
//                 </Col>
//               </Row>
//             </Container>
//           </section>
//         </>
//       }
//     </>
//   );
// };

// export default NewPopulerCategory;


