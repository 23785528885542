// import React from 'react'

// export default function ProuctBoxPopulerCategory() {
//   return (
//     <div>

//     </div>
//   )
// }
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Img from "../../../helpers/utils/BgImgRatio";
import { replaceWhiteSpacesWithDashSymbolInUrl } from "../../../helpers/ConversionHelper";
import { getLanguageCodeFromSession, GetLocalizationControlsJsonDataForScreen, replaceLoclizationLabel } from "../../../helpers/CommonHelper";
import Config from "../../../helpers/Config";
import ProductRatingStars from "./ProductRatingStars";
import rootAction from "../../../stateManagment/actions/rootAction";
import { showErrorMsg } from "../../../helpers/ValidationHelper";
import { LOADER_DURATION } from "../../../helpers/Constants";
import GlobalEnums from "../../../helpers/GlobalEnums";
import "./ProductBoxPopulerCategory.css";

import { makeProductShortDescription } from "../../../helpers/ConversionHelper";

const ProductBoxPopulerCategory = ({ item, ProductDetailPageForceUpload }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [adminPanelBaseURL, setBaseUrl] = useState(Config['ADMIN_BASE_URL']);
    const [onSale, setOnSale] = useState(item.DiscountedPrice != undefined && item.DiscountedPrice != null && item.DiscountedPrice > 0);
    const customerCompareList = useSelector(state => state.cartReducer.customerCompareList);
    const [langCode, setLangCode] = useState('');
    const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
    const [showPopover, setShowPopover] = useState(false);
    const popoverRef = useRef(null);
    const [copyurl, setCopyUrl] = useState(false)

    useEffect(() => {
        const dataOperationInUseEffect = async () => {
            let lnCode = getLanguageCodeFromSession();
            setLangCode(lnCode);
            let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(GlobalEnums.Entities["ProductBox_1"], null);
            if (arryRespLocalization && arryRespLocalization.length > 0) {
                setLocalizationLabelsArray(arryRespLocalization);
            }
        };

        dispatch(rootAction.commonAction.setLoading(true));
        dataOperationInUseEffect().catch(console.error);
        setTimeout(() => {
            dispatch(rootAction.commonAction.setLoading(false));
        }, LOADER_DURATION);
    }, [dispatch]);

    const togglePopover = () => {
        setShowPopover(!showPopover);
    };

    const reloadProductDetail = (_productId, _categoryName, _productName) => {
        let productDetailUrlFromForceReload = `/${getLanguageCodeFromSession()}/product-detail/${_productId}/${replaceWhiteSpacesWithDashSymbolInUrl(_categoryName) ?? "shop"}/${replaceWhiteSpacesWithDashSymbolInUrl(_productName)}`;
        if (ProductDetailPageForceUpload && _productId) {
            navigate(productDetailUrlFromForceReload, { replace: true });
            window.location.reload();
        }
    };

    const handleCustomerWishList = (ProductID, ProductName, Price, DiscountedPrice, DiscountId, IsDiscountCalculated, CouponCode, defaultImage) => {
        // Implement wish list logic here
    };

    const handleCompareList = (ProductId) => {
        try {
            if (!customerCompareList || !customerCompareList.find(obj => obj.ProductId === ProductId)) {
                let customerCompareLocal = [...(customerCompareList || []), { ProductId }];
                dispatch(rootAction.cartAction.setCustomerCompareList(customerCompareLocal));
            }
            navigate('/' + getLanguageCodeFromSession() + '/compare');
        } catch (err) {
            console.log(err);
            showErrorMsg("An error occurred. Please try again!");
        }
    };

    return (
        <Fragment>
            <div className="product-box img">
                <div className="product-imgbox ">
                    <div className="product-front">
                        {(() => {
                            let urlViewDetailImage = `/${getLanguageCodeFromSession()}/product-detail/${item.ProductId}/${replaceWhiteSpacesWithDashSymbolInUrl(item.CategoryName) ?? "shop"}/${replaceWhiteSpacesWithDashSymbolInUrl(item.ProductName)}`;
                            return (
                                <Link to={urlViewDetailImage} onClick={() => reloadProductDetail(item.ProductId, item.CategoryName, item.ProductName)}>
                                    {item?.ProductImagesJson?.slice(0, 1)?.map((img, imgIdx) =>
                                        <Img key={imgIdx} src={adminPanelBaseURL + img.AttachmentURL} className="img-fluid" alt="product" />
                                    )}
                                </Link>
                            );
                        })()}
                    </div>

                </div>
                <div className="product-detail detail-inline">
                    <Link to={`/${getLanguageCodeFromSession()}/product-detail/${item.ProductId}/${replaceWhiteSpacesWithDashSymbolInUrl(item.CategoryName) ?? "shop"}/${replaceWhiteSpacesWithDashSymbolInUrl(item.ProductName)}`}>
                        <p className="price-title " id="nameOfProduct">{makeProductShortDescription(item.ProductName, 30)}</p>
                    </Link>

                </div>

            </div>
        </Fragment>
    );
};

export default ProductBoxPopulerCategory;
